<template>
  <div id="auth-layout">
    <MobileView class="header-layout">
      <div class="body-layout">
        <v-row>
          <v-col cols="9">
            <h4>ยินดีต้อนรับ</h4>
            <h4>{{ userName }}</h4>
          </v-col>
          <v-col cols="3">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="d-flex ml-auto" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link to="point-history">
                  <v-list-item-title>หน้าหลัก</v-list-item-title>
                </v-list-item>
                <v-list-item link to="setting">
                  <v-list-item-title>จัดการความเป็นส่วนตัว</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-card class="mt-4" color="rgb(255, 255, 255, 0.8)">
          <v-card-text>
            <v-row>
              <v-col cols="9">
                <h5>สมาชิก เดอะ สตรีท พอยท์</h5>
                <h5>{{ phoneNumber }}</h5>
              </v-col>
              <v-col cols="3">
                <v-img
                  class="ml-auto"
                  :src="require('@/assets/logo.svg')"
                  width="79px"
                />
              </v-col>
            </v-row>
            <h2 class="mt-4">{{ numberWithCommas(point) }}</h2>
            <div class="expire-point-section">
              <div v-for="(item, index) in pointExpire" :key="index">
                {{ numberWithCommas(item.remainPoint) }} คะแนน หมดอายุ
                {{ moment(item.expireDate).format('DD/MM/YYYY') }}
              </div>
            </div>
            <h5>คะแนนสะสมปัจจุบัน</h5>
          </v-card-text>
        </v-card>
      </div>
      <v-sheet class="tab-layout" v-if="displayTab">
        <v-tabs v-model="tab" color="#000" slider-color="#f7941e" grow>
          <v-tab
            v-for="(item, index) in tabItems"
            :key="index"
            :href="`#${item.pathName}`"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-sheet>
    </MobileView>
    <slot />
    <CustomModal
      :modal="modal"
      @confirm="submitAction"
      disableCancel
      :disabled="consentDetail && !consentDetail.checkbox"
      block
    >
      <template v-slot:header>
        <div v-if="consentDetail !== null">
          <h4>{{ consentDetail.title }}</h4>
        </div>
      </template>
      <template v-slot:body>
        <div class="container" v-if="consentDetail !== null">
          <!-- <div v-for="(item, index) in termCon" :key="index"> -->
          <div v-html="consentDetail.content" />
          <!-- </div> -->
        </div>
        <div class="d-flex check-box-input" v-if="consentDetail !== null">
          <v-checkbox class="mt-0" v-model="consentDetail.checkbox" />
          <span>
            ข้าพเจ้าได้อ่านและยอมรับ ข้อตกลงและเงื่อนไข สมาชิก เดอะ สตรีท พอยท์
          </span>
        </div>
      </template>
    </CustomModal>
    <CustomModal
      :modal="mktModal"
      @confirm="submitMktAction"
      disableCancel
      block
    >
      <template v-slot:header>
        <div v-if="mktConsentDetail !== null">
          <h4>{{ mktConsentDetail.title }}</h4>
        </div>
      </template>
      <template v-slot:body>
        <div class="container black--text" v-if="mktConsentDetail !== null">
          <!-- <div v-for="(item, index) in termCon" :key="index"> -->
          <div v-html="mktConsentDetail.content" />
          <!-- </div> -->
        </div>
        <div class="d-flex check-box-input" v-if="mktConsentDetail !== null">
          <v-checkbox class="mt-0" v-model="mktConsentDetail.checkbox" />
          <span>
            ฉันต้องการรับข้อมูลข่าวสารทางการตลาด
            <br />รายละเอียดการให้ความยินยอม
          </span>
        </div>
        <div>
          โปรดศึกษา
          <a
            color="primary"
            href="https://www.thestreetratchada.com/upload/pdpa/2-customer.pdf"
            target="_blank"
            >นโยบายความเป็นส่วนตัวสำหรับลูกค้า</a
          >
        </div>
      </template>
    </CustomModal>
    <CustomModal :modal="alert" @confirm="redirectAction" disableCancel block>
      <!-- <template v-slot:header> Header </template> -->
      <template v-slot:body>
        <div class="text-center">
          "ศูนย์การค้า เดอะ สตรีท รัชดา<br />
          ขอความร่วมมือ ท่านกรอกข้อมูลเพิ่มเติม<br />
          เพื่อช่วยให้บริษัทสามารถจัดหาสินค้า<br />
          และบริการได้อย่างเหมาะสม<br />
          ตามความต้องการของท่าน<br />
          หรือตามที่ท่านเลือกใช้เป็นการเฉพาะ<br />
          ขอบคุณครับ"
        </div>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import moment from 'moment'
import { forEach, size } from 'lodash'
import Utils from '@/mixin'
import {
  acceptConsent,
  checkConsent,
  acceptMktConsent,
  checkMktConsent,
} from '@/middleware/Auth'

import CustomModal from '@/components/Modal'
import MobileView from '@/components/MobileView'

export default {
  mixins: [Utils],
  components: {
    CustomModal,
    MobileView,
  },
  computed: {
    userName() {
      const { firstName, lastName } = this.userProfile
      return `${firstName} ${lastName}`
    },
    phoneNumber() {
      const { phone } = this.userProfile
      const phoneFormat = (input) => {
        if (!input || isNaN(input))
          return `input must be a number was sent ${input}`
        if (typeof input !== 'string') input = input.toString()
        if (input.length === 10) {
          return input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        } else if (input.length < 10) {
          return 'was not supplied enough numbers please pass a 10 digit number'
        } else if (input.length > 10) {
          return 'was supplied too many numbers please pass a 10 digit number'
        } else {
          return 'something went wrong'
        }
      }
      return phoneFormat(phone) || ''
    },
    point() {
      const { totalPoint } = this.userProfile
      return totalPoint
    },
    tab() {
      return this.$route.name || null
    },
    pointExpire() {
      const { pointExpireSummaries } = this.userProfile
      return pointExpireSummaries
    },
  },
  data: () => ({
    tabItems: [{ name: 'ประวัติคะแนน', pathName: 'Point-History' }],
    displayTab: true,
    modal: false,
    consentList: null,
    consentDetail: null,
    confirmData: {
      confirmText: 'ปิด',
    },
    mktInit: true,
    mktModal: false,
    mktConsentDetail: null,
    countPdpa: 0,
    alert: false,
  }),
  mounted() {
    this.getInitialData()
  },
  destroyed() {
    this.countPdpa = 0
  },
  methods: {
    moment,
    async getInitialData() {
      window.loading(true)
      const { name } = this.$route
      if (name === 'Setting') this.displayTab = false
      else this.displayTab = true
      await this.getUserProfile()
      this.getConsent()
      window.loading(false)
    },
    async getConsent() {
      const { countPdpa } = this
      const consent = await checkConsent()
      const res = consent
      const result = []
      forEach(res, (i) => {
        const { isForce, title, content } = i
        if (isForce) {
          this.modal = true
          this.mktInit = true
          result.push({
            ...i,
            id: i.id,
            title,
            content,
          })
        }
      })
      if (size(result) > 0) {
        this.consentList = result
        this.consentDetail = result[0]
        this.countPdpa = countPdpa + 1
      } else {
        const { name } = this.$route
        if (name !== 'Setting' && countPdpa !== 0 && this.mktInit)
          this.getMktConsent()
      }
    },
    async getMktConsent() {
      // const { checkMkt } = this;
      // if (!checkMkt) {
      //   this.toggleCheckMkt();
      const mktConsent = await checkMktConsent()
      if (mktConsent !== null) {
        this.mktModal = true
        this.mktConsentDetail = mktConsent
        this.mktInit = false
      }
      // }
    },
    async submitAction(event = false) {
      if (event) {
        const { consentDetail } = this
        const result = [
          {
            consentId: consentDetail.id,
            isAccept: true,
          },
        ]
        this.modal = false
        window.loading(true)
        await acceptConsent(result).then(() => {
          this.getConsent()
        })
        window.loading(false)
      }
    },
    async submitMktAction(event = false) {
      if (event) {
        const { mktConsentDetail } = this
        const isAccept = mktConsentDetail.checkbox || false
        this.mktModal = false
        window.loading(true)
        await acceptMktConsent(isAccept)
        window.loading(false)
      }
    },
    numberWithCommas(number) {
      return new Intl.NumberFormat().format(number)
    },
    redirectAction() {
      this.$router.push({ name: 'Personal-Information' })
    },
  },
  watch: {
    $route: {
      handler() {
        this.getInitialData()
      },
      deep: false,
    },
    userProfile: {
      handler() {
        const {
          birthDate,
          firstName,
          phone,
          gender,
          lastName,
          title,
          addresses,
        } = this.userProfile
        const check = !!(
          birthDate &&
          firstName &&
          phone &&
          gender &&
          lastName &&
          title &&
          addresses
        )
        if (check) {
          return
        } else this.alert = true
      },
      deep: false,
    },
  },
}
</script>

<style lang="scss">
$black: #000;
$gray: #f2f3f5;
$grey: rgba(0, 0, 0, 0.6);
$orange: #f7941e;

#auth-layout {
  min-height: 100vh;
  position: relative;
  .header-layout {
    background: $gray;
    > .row {
      > .col {
        padding: 0;
      }
    }
    .body-layout {
      background: url('../assets/auth-bg.png');
      background-size: cover;
      padding: 20px;
      .v-card {
        .v-card__text {
          color: $black;
          h5 {
            line-height: 1;
          }
        }
      }
    }
    .tab-layout {
      margin: 0 2px;
      padding: 13px 20px;
    }
  }
  .loading-process {
    z-index: 999;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.3);
    > div {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .expire-point-section {
    text-align: right;
    position: absolute;
    line-height: 1;
    bottom: 0;
    right: 0;
    padding: 0 16px 16px 0;
  }
  .check-box-input {
    color: $grey;
    font-size: 16px;
    .cursor-pointer {
      cursor: pointer;
    }
  }
}
</style>
