<template>
  <div class="register-container">
    <v-row class="ma-0 ma-sm-auto">
      <v-col cols="12" offset-lg="2" lg="8" class="pa-0 pa-sm-auto">
        <v-card color="#f2f3f5" flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="8">
                <h1 class="no-ellipsis">
                  ลงทะเบียนสมาชิก <br />เดอะ สตรีท พอยท์
                </h1>
              </v-col>
              <v-col cols="12" md="4">
                <v-img
                  class="ma-auto ml-sm-auto"
                  :src="require('@/assets/logo.svg')"
                  width="194px"
                />
              </v-col>
            </v-row>
            <h3 class="mt-5">ข้อมูลลูกค้า / Customer Information</h3>
            <v-form
              class="mt-8"
              v-model="valid"
              :lazy-validation="false"
              @submit.prevent="submitForm"
            >
              <v-row>
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="formData.title"
                    :items="titleItems"
                    persistent-placeholder
                    placeholder="กรุณาเลือก"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                  >
                    <template v-slot:label>
                      คำนำหน้า / Title
                      <span class="red--text">*</span>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    persistent-placeholder
                    placeholder="ชื่อ / Name"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.firstName"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      ชื่อ / First Name
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    persistent-placeholder
                    placeholder="นามสกุล / Last Name"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.lastName"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      นามสกุล / Last Name
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <div>
                    เพศ / Gender
                    <span class="red--text">*</span>
                  </div>
                  <v-radio-group
                    v-model="formData.gender"
                    class="mt-0"
                    row
                    :rules="[rules.required]"
                  >
                    <v-radio label="ชาย / Men" value="men" />
                    <v-radio label="หญิง / Women" value="women" />
                    <v-radio label="อื่นๆ" value="other" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <BirthDateInput
                    label="วันเดือนปีเกิด / Date of Birth"
                    placeholder="DDMMYYYY"
                    format="DD-MM-YYYY"
                    persistentPlaceholder
                    :value="formData.birthDate"
                    @input="updateDateInput"
                    @clear="onClear"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    persistent-placeholder
                    placeholder="000-000-0000"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    @keypress="validateNumeric"
                    v-model="formData.phoneNumber"
                    :rules="[rules.required, rules.max]"
                  >
                    <template v-slot:label>
                      เบอร์โทรศัพท์ / Mobile Phone No.
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="อีเมล / E-mail Address"
                    persistent-placeholder
                    placeholder="Example@mail.com"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.email"
                    type="email"
                    :rules="[rules.emailValidate]"
                  />
                </v-col>
              </v-row>
              <hr />
              <h3 class="mt-5">ที่อยู่ลูกค้า / Customer Address</h3>
              <v-row class="mt-6">
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="formData.postalCode"
                    :items="postalCodeList"
                    persistent-placeholder
                    placeholder="00000"
                    :item-value="
                      (item) => {
                        return item
                      }
                    "
                    :item-text="
                      (item) => {
                        return `${item.postcode} ${item.subDistrict} ${item.district} ${item.province}`
                      }
                    "
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    @keypress="validateNumeric"
                    @change="changePostalCode"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      รหัสไปรษณีย์ / Postal Code
                      <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="formData.province"
                    :items="provinceList"
                    persistent-placeholder
                    placeholder="กรุณาเลือก"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    @change="inputProvince"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      จังหวัด / Province
                      <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="formData.district"
                    :items="districtList"
                    persistent-placeholder
                    placeholder="กรุณาเลือก"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    @change="inputDistrict"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      อำเภอ / เขต / District
                      <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <hr />
              <div
                class="d-flex check-box-input mt-3"
                v-if="mktConsentDetail !== null"
              >
                <v-checkbox
                  class="mt-0 pt-0"
                  v-model="formData.mktconsent"
                  hide-details
                />
                <span>
                  ฉันต้องการรับข้อมูลข่าวสารทางการตลาด
                  <br />
                  <span
                    class="cursor-pointer primary--text"
                    @click.stop="toggleMarket"
                  >
                    นโยบายการรับข้อมูลข่าวสารทางการตลาด
                  </span>
                </span>
              </div>
              <div v-if="consentList !== null">
                <div
                  class="d-flex check-box-input mt-2"
                  v-for="(item, index) in consentList"
                  :key="index"
                >
                  <v-checkbox
                    class="mt-0 pt-0"
                    v-model="item.check"
                    hide-details
                    :rules="[rules.required]"
                  />
                  <span>
                    ข้าพเจ้าได้อ่านและยอมรับ
                    <span
                      class="cursor-pointer primary--text"
                      @click.stop="getTermCon(index)"
                    >
                      ข้อตกลงและเงื่อนไข
                    </span>
                    สมาชิก เดอะ สตรีท พอยท์
                  </span>
                </div>
              </div>
              <div class="remark">
                * สมาชิก The Street Point สามารถดูคะแนนสะสมได้ที่ <br />LINE
                OFFICIAL ACCOUNT
                <a color="primary" href="line://ti/p/@thestreetratchada"
                  >@thestreetratchada</a
                >
              </div>
              <div class="remark">
                โปรดศึกษา
                <a
                  color="primary"
                  href="https://www.thestreetratchada.com/upload/pdpa/2-customer.pdf"
                  target="_blank"
                  >นโยบายความเป็นส่วนตัวสำหรับลูกค้า</a
                >
              </div>
              <v-row>
                <v-col cols="12" sm="4" md="3" lg="2">
                  <v-btn
                    class="mt-4"
                    color="#f7941e"
                    type="submit"
                    block
                    :disabled="!valid || checkTerm"
                  >
                    บันทึก
                  </v-btn>
                </v-col>
                <!-- <v-col cols="12" sm="4" md="3" lg="2">
                  <v-btn class="mt-sm-4" outlined block @click="backHome"
                    >ยกเลิก</v-btn
                  >
                </v-col> -->
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <TermCon
      :modal="termModal"
      :toggle="toggleTermCon"
      :detail="consentDetail"
    />
    <Market
      :modal="marketModal"
      :toggle="toggleMarket"
      :detail="mktConsentDetail"
    />
  </div>
</template>

<script>
import { forEach, reduce } from 'lodash'
// import errorCode from "@/utils/errorCode";

import BirthDateInput from '@/components/BirthDateInput'
import Market from './Market.vue'
import TermCon from './TermCon.vue'

import {
  getCodeToken,
  getAuthToken,
  postRegister,
  // checkTokenData,
  getDistrict,
  getProvince,
  getPostcodes,
  getConsent,
  getMktConsent,
} from '@/middleware/Auth'

export default {
  components: {
    BirthDateInput,
    Market,
    TermCon,
  },
  computed: {
    checkTerm() {
      const { consentList } = this
      let result = false
      forEach(consentList, (i) => {
        if (i.check === false) result = true
      })
      return result
    },
    postalCodeList() {
      return this.postalCodeItems
    },
    districtList() {
      return this.districtItems
    },
    provinceList() {
      return this.provinceItems
    },
  },
  data: () => ({
    valid: false,
    provinceItems: [],
    districtItems: [],
    postalCodeItems: [],
    titleItems: [
      { value: 'mr', name: 'นาย' },
      { value: 'ms', name: 'นาง' },
      { value: 'mrs', name: 'นางสาว' },
    ],
    formData: {
      title: '',
      firstName: '',
      lastName: '',
      birthDate: null,
      gender: '',
      phoneNumber: '',
      email: '',
      postalCode: '',
      province: '',
      district: '',
      mktconsent: false,
    },
    rules: {
      required: (value) => !!value || 'Require field.',
      emailValidate: (email) => {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
        if (email === '' || re.test(String(email).toLowerCase())) {
          return true
        }
        return 'Invalid format.'
      },
      max: (value) => (!!value && value.length === 10) || 'Invalid format.',
    },
    flag: false,
    marketModal: false,
    termModal: false,
    consentList: null,
    consentDetail: null,
    mktConsentDetail: null,
  }),
  async beforeCreate() {
    try {
      // console.log("before");
      window.loading(true)
      if (!window.$authToken) {
        const app_access_token = await getCodeToken()
        window.$authToken = await getAuthToken(app_access_token)
      }
      this.flag = true
      // console.log("-- set authToken");
      window.loading(false)
    } catch (err) {
      window.loading(false)
      this.$router.replace({
        name: 'error',
        params: err,
      })
    }
  },
  mounted() {
    this.getInitialData()
  },
  methods: {
    async getInitialData() {
      const district = await getDistrict({ listType: 'all' })
      const province = await getProvince({ listType: 'all' })
      const postcode = await getPostcodes({ listType: 'all' })
      this.provinceItems = province
      this.districtItems = district
      this.postalCodeItems = postcode
      const consent = await getConsent()
      this.consentList = consent
      const mktConsent = await getMktConsent()
      this.mktConsentDetail = mktConsent
    },
    async changePostalCode() {
      const { postalCode } = this.formData
      const district = await getDistrict({
        listType: 'all',
        provinceId: postalCode?.provinceId,
      })
      this.districtItems = district
      this.formData = {
        ...this.formData,
        province: { name: postalCode?.province, id: postalCode?.provinceId },
        district: { name: postalCode?.district, id: postalCode?.districtId },
      }
    },
    async inputProvince() {
      const { province } = this.formData
      const district = await getDistrict({
        listType: 'all',
        provinceId: province,
      })
      this.districtItems = district
      this.formData = {
        ...this.formData,
        district: '',
        postalCode: '',
      }
    },
    async inputDistrict() {
      this.formData = {
        ...this.formData,
        postalCode: '',
      }
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode >= 48 && charCode <= 57) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    backHome() {
      this.$router.push({
        name: 'Home',
      })
    },
    onClear() {
      this.formData.birthDate = null
    },
    updateDateInput(e) {
      this.formData.birthDate = e
    },
    async submitForm() {
      window.loading(true)
      try {
        const res = await this.postUser()
        window.loading(false)
        if (res) this.$router.push({ name: 'Register-Success' })
      } catch (err) {
        window.loading(false)
        console.log(err)
        let text = ''
        switch (err.message) {
          case 'This email already registered: register error':
            text = 'อีเมล์นี้เป็นสมาชิก The Street Point ในระบบแล้ว'
            break
          case 'This phone number already registered: register error':
            text = 'หมายเลขนี้เป็นสมาชิก The Street Point ในระบบแล้ว'
            break
          case 'Invalid data':
            text = 'กรุณากรอกข้อมูลให้ถูกต้อง'
            break
          default:
            break
        }
        alert(
          text
          // err.code
          //   ? `${err.message} (${err.code})`
          //   : `${errorCode(401).message} (${errorCode(401).code})`
        )
      }
    },
    async postUser() {
      const { formData } = this
      const {
        utm_campaign,
        utm_content,
        utm_id,
        utm_medium,
        utm_source,
        utm_term,
      } = this.$route.query
      const utm = {
        id: utm_id,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        term: utm_term,
        content: utm_content,
      }
      const { consentList } = this
      const consents = reduce(
        consentList,
        (a, b) => {
          a.push({ consentId: b.id, isAccept: true })
          return a
        },
        []
      )
      return await postRegister({
        title: formData.title.value,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phoneNumber,
        birthDate: formData.birthDate,
        gender: formData.gender,
        address: {
          type: 'current',
          // address: "200/200 หมู่9 บ้านโคกหนองใหญ่ ถนนหมาเดิน",
          // subDistrictId: 1,
          subDistrictId: formData.postalCode?.subDistrictId,
          districtId: formData.district?.id,
          provinceId: formData.province?.id,
          postcode: formData.postalCode?.postcode,
        },
        authChannel: 'phone',
        authCredential: {
          phone: formData.phoneNumber,
        },
        ...(window.$liffData.accessToken && {
          link: {
            channel: 'line',
            accessToken: window.$liffData.accessToken,
          },
        }),
        ...(this.$route.query && {
          utm,
        }),
        mktconsent: formData.mktconsent,
        consents,
      })
      // console.log("-- set api token");
      // window.$tokenData = checkTokenData(window.$apiToken);
      // console.log("-- check user data");
    },
    getTermCon(index) {
      const { consentList } = this
      this.consentDetail = consentList[index]
      this.toggleTermCon()
    },
    toggleTermCon() {
      this.termModal = !this.termModal
    },
    toggleMarket() {
      this.marketModal = !this.marketModal
    },
  },
  watch: {
    flag: {
      handler() {
        this.getInitialData()
      },
      deep: false,
    },
  },
}
</script>

<style lang="scss" scoped>
$black: #000;
$grey: rgba(0, 0, 0, 0.6);

.register-container {
  background: url('../../assets/bg.svg');
  background-size: cover;
  height: 100%;
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
  }
  .v-card {
    .v-card__text {
      color: $black;
      @media (min-width: 768px) {
        padding: 64px 115px;
      }
      .remark {
        font-size: 20px;
      }
      a {
        text-decoration: none;
        text-transform: lowercase;
      }
      .check-box-input {
        color: $grey;
        .cursor-pointer {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
